<script>
import axios from 'axios';
import SelectStations from '@/components/custom/SelectStations.vue';
import SelectProduct from '@/components/custom/SelectProduct.vue';
import SelectCountries from '@/components/custom/SelectCountries.vue';
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import RollingStocks from "@/core/application/RollingStocks"
import Swal from "sweetalert2";

export default {
  components: {
    SelectStations,
    SelectProduct,
    SelectCountries,
    Multiselect
  },
  data() {
    return {
      order_info: null,
      territory_options: [],
      rolling_stock_a: RollingStocks.A,
      rolling_stock_b: RollingStocks.B,
      incoterms: [
        "FOR",
        "EXW",
        "FAS",
        "FOB",
        "FCA",
        "CFR",
        "CIF",
        "CPT",
        "CIP",
        "DAT",
        "DAP",
        "DDP",
      ],
      showPdfPreviewModal: false,
      pdf_url: '',
      saving: false
    }
  },
  computed: {
    order() {
      return this.order_info || {}
    },
    incotermCombinations() {
      let combinations = [];
      for (let i = 0; i < this.incoterms.length; i++) {
        for (let j = 0; j < this.incoterms.length; j++) {
          combinations.push(`${this.incoterms[i]}-${this.incoterms[j]}`);
        }
      }
      return combinations;
    },
  },
  methods: {
    async getOrderInfo() {
      if (!this.$route.params.id) return alert("Order not found")
      try {
        let response = await axios.get(`/pre_order/list/${this.$route.params.id}/`)
        this.order_info = response.data
      } catch {
        alert("Error")
      }
    },
    async getTerritories() {
      try {
        let response = await axios.get(`/core/territories/`)
        this.territory_options = response.data.results
      } catch {
        console.log("Failed to get territories")
      }
    },
    onStationSelect(event) {
      if (!event) return

      if (event.option === "departure") {
        if (event.value === null) return this.order.departure = null
        this.order.departure = {
          id: event.value.value,
          name: event.value.label,
          code: event.value.code,
        }
      } else if (event.option === "destination") {
        if (event.value === null) return this.order.destination = null
        this.order.destination = {
          id: event.value.value,
          name: event.value.label,
          code: event.value.code,
        }
      }
    },
    onProductSelect(event) {
      if (!event) return this.order.product = null
      this.order.product = {
        id: event.value,
        name: event.label,
        hc_code: event.hc_code,
        etcng_code: event.etcng || ''
      }
    },
    onCountrySelect(event) {
      if (!event) return

      if (event.option === "departure") {
        if (event.value === null) return this.order.departure_country = null
        this.order.departure_country = {
          id: event.value.value,
          name: event.value.label,
        }
      } else if (event.option === "destination") {
        if (event.value === null) return this.order.destination_country = null
        this.order.destination_country = {
          id: event.value.value,
          name: event.value.label,
        }
      }
    },
    validateQuantity() {
      if (this.order.quantity === null) return this.order.quantity = 1
      if (this.order.quantity >= 1 && this.order.quantity >= 300) {
        this.order.quantity = 300
      } else if (this.order.quantity < 1) {
        this.order.quantity = 1
      }
    },
    validateAgreedRate() {
      if (this.order.agreed_rate === null) return this.order.agreed_rate = 1
      if (this.order.agreed_rate >= 0 && this.order.agreed_rate >= 100000) {
        this.order.agreed_rate = 100000
      } else if (this.order.agreed_rate < 0) {
        this.order.agreed_rate = 0
      }
    },
    validateTonne() {
      if (this.order.weight === null) return this.order.weight = 1
      if (this.order.weight >= 1 && this.order.weight >= 1000) {
        this.order.weight = 1000
      } else if (this.order.weight < 1) {
        this.order.weight = 1
      }
    },

    async updateOrder() {
      let rolling_stock_1 = this.order.rolling_stock_1
      let rolling_stock_2 = this.order.rolling_stock_2
      let start_date = this.order.period_from
      let end_date = this.order.period_to
      let shipper = this.order.shipper
      let consignee = this.order.consignee
      let departure = this.order.departure
      let destination = this.order.destination
      let product = this.order.product
      let territories = this.order.territories
      let departure_country = this.order.departure_country
      let destination_country = this.order.destination_country
      let border_crossing = this.order.border_crossing
      let incoterm = this.order.conditions_of_carriage
      let quantity = this.order.quantity
      let agreed_rate = this.order.agreed_rate
      let weight = this.order.weight
      let agreed_rate_per_ton = this.order.agreed_rate_per_ton
      let wagons = this.order.wagons

      let validation_text = ''

      if (!rolling_stock_1) validation_text += 'Rolling stock 1, <br>'
      if (!rolling_stock_2) validation_text += 'Rolling stock 2, <br>'
      if (!start_date) validation_text += 'Period from, <br>'
      if (!end_date) validation_text += 'Period to, <br>'
      if (!shipper) validation_text += 'Shipper, <br>'
      if (!consignee) validation_text += 'Consignee, <br>'
      if (!departure) validation_text += 'Departure station, <br>'
      if (!destination) validation_text += 'Destination station, <br>'
      if (!product) validation_text += 'Product, <br>'
      if (!territories) validation_text += 'Territories, <br>'
      if (!departure_country) validation_text += 'Departure country, <br>'
      if (!destination_country) validation_text += 'Destination country, <br>'
      if (!incoterm) validation_text += 'Conditions of carriage, <br>'
      if (!quantity) validation_text += 'Quantity, <br>'
      if (!agreed_rate && agreed_rate !== 0) validation_text += 'Agreed rate, <br>'
      if (!weight) validation_text += 'Weight, <br>'
      if (!agreed_rate_per_ton && agreed_rate_per_ton !== 0) validation_text += 'Agreed rate per tonne, <br>'

      if (validation_text !== '') {
        await Swal.fire({
          icon: 'error',
          title: 'Following fields are required:',
          html: validation_text,
        })
        return
      }

      try {
        this.saving = true
        let response = await axios.put(`/pre_order/list/${this.$route.params.id}/update/`, {
          rolling_stock_1: rolling_stock_1,
          rolling_stock_2: rolling_stock_2,
          period_from: start_date,
          period_to: end_date,
          shipper: shipper,
          consignee: consignee,
          departure_id: departure.id,
          destination_id: destination.id,
          product_id: product.id,
          territories: territories.map(t => t.id),
          departure_country_id: departure_country.id,
          destination_country_id: destination_country.id,
          border_crossing: border_crossing,
          conditions_of_carriage: incoterm,
          quantity: quantity,
          agreed_rate: agreed_rate,
          weight: weight,
          agreed_rate_per_ton: agreed_rate_per_ton,
          wagons: wagons,
          paid_telegram: this.order.paid_telegram || false,

          loading_type: 'wagon',
          sending_type: '',
          date: this.order.date,
          lang: 'en'
        })
        this.saving = false
        await this.getPdfFile(process.env.VUE_APP_ORDER_URL + response.data.file)
        this.showPdfPreviewModal = true
        await Swal.fire({
          icon: 'success',
          title: 'Order updated successfully',
          showConfirmButton: false,
          timer: 1500
        })
      } catch {
        this.saving = false
        alert("Error")
      }
    },

    async getPdfFile(url) {
      await axios.get(url, {
        responseType: 'arraybuffer'
      }).then(response => {
        const blob = new Blob([response.data], {type: 'application/pdf'});
        this.pdf_url = ''
        this.pdf_url = URL.createObjectURL(blob);
      }).catch(error => {
        console.log("error", error)
        return ''
      });
    }
  },
  created() {
    this.getOrderInfo();
    this.getTerritories();
  }
}

</script>


<template>

  <b-modal v-model="showPdfPreviewModal" hide-footer title="Wagon Pre-Order PDF" dialog-class="modal-fullscreen-sm-down"
           class="v-modal-custom" hide-header-close centered size="xl">
    <iframe :src="pdf_url" width="100%" height="700px"></iframe>
  </b-modal>


  <b-row class="justify-content-center" v-if="Object.keys(order).length > 0">
    <b-col sm="12" md="12" xl="9" xxl="8" style="max-width: 1400px">
      <b-card>
        <b-alert show variant="success" class="border-0 " role="alert">
          <strong> You are updating WAGON pre-order ! </strong>
        </b-alert>

        <form @submit.prevent="updateOrder()" class="row">

          <!-- CONTAINER & SENDING TYPE -->
          <div class="col-12 col-md-6 mb-3 py-1">
            <label class="form-label">
              Rolling Stock 1
              <span class="text-danger">*</span>
            </label>
            <Multiselect v-model="order.rolling_stock_1" :allow-empty="false" :hideSelected="false"
                         placeholder="Rolling stock 1" :options="rolling_stock_a"/>
            <div class="mt-1" v-show="!order.rolling_stock_1">
              <span class="text-danger fw-medium">Required field</span>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-3 py-1">
            <label class="form-label">Rolling Stock 2
              <span class="text-danger">*</span>
            </label>
            <Multiselect v-model="order.rolling_stock_2" :allow-empty="false" :hideSelected="false"
                         placeholder="Rolling stock 2" :options="rolling_stock_b"/>
            <div class="mt-1" v-show="!order.rolling_stock_2">
              <span class="text-danger fw-medium">Required field</span>
            </div>
          </div>

          <!-- START & END PERIOD -->
          <div class="col-12 col-md-6 col-lg-3 mb-3 py-1">
            <label for="startPeriod" class="form-label">Start Period
              <span class="text-danger">*</span>
            </label>
            <input v-model="order.period_from" type="date" class="form-control" id="startPeriod"
                   placeholder="Start Period">
            <div class="mt-1" v-show="!order.period_from">
              <span class="text-danger fw-medium">Required field</span>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 mb-3 py-1">
            <label for="endPeriod" class="form-label">End Period
              <span class="text-danger">*</span>
            </label>
            <input v-model="order.period_to" type="date" class="form-control" id="endPeriod"
                   placeholder="End Period">
            <div class="mt-1" v-show="!order.period_to">
              <span class="text-danger fw-medium">Required field</span>
            </div>
          </div>

          <!-- SHIPPER & CONSIGNEE -->
          <div class="col-12 col-md-6 col-lg-3 mb-3 py-1">
            <label for="shipper" class="form-label">Shipper
              <span class="text-danger">*</span>
            </label>
            <input v-model="order.shipper" type="text" class="form-control" id="shipper"
                   placeholder="Enter shipper">
            <div class="mt-1" v-show="!order.shipper">
              <span class="text-danger fw-medium">Required field</span>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3 mb-3 py-1">
            <label for="consignee" class="form-label">Consignee
              <span class="text-danger">*</span>
            </label>
            <input v-model="order.consignee" type="text" class="form-control" id="consignee"
                   placeholder="Enter consignee">
            <div class="mt-1" v-show="!order.consignee">
              <span class="text-danger fw-medium">Required field</span>
            </div>
          </div>

          <SelectStations :current_departure="order.departure ? {
                        id: order.departure.id,
                        name: order.departure.name,
                        code: order.departure.code
                    } : null" :current_destination="order.destination ? {
    id: order.destination.id,
    name: order.destination.name,
    code: order.destination.code
} : null" @onSelect="onStationSelect"/>

          <SelectProduct :ratio="[3, 3, 3]" :current_product="order.product ? {
                        id: order.product.id,
                        name: order.product.name,
                        hc_code: order.product.hc_code,
                        etcng_code: order.product.etcng_code
                    } : null" @onSelect="onProductSelect"/>

          <!-- SELECT TERRITORIES -->
          <div class="col-12 col-md-6 col-lg-3 mb-3">
            <label for="departure_country" class="form-label">Territories
              <span class="text-danger">*</span>
            </label>
            <Multiselect v-model="order.territories" :object="true" :allow-empty="false" :closeOnSelect="false"
                         :hideSelected="false" placeholder="Select territories" mode="multiple" valueProp="id"
                         label="name" :options="territory_options"/>
            <div class="mt-1" v-show="order.territories.length <= 0">
              <span class="text-danger fw-medium">Required field</span>
            </div>
          </div>

          <SelectCountries :current_departure="order.departure_country ? {
                        id: order.departure_country.id,
                        name: order.departure_country.name,
                    } : null" :current_destination="order.destination_country ? {
    id: order.destination_country.id,
    name: order.destination_country.name,
} : null" @onSelect="onCountrySelect"/>

          <div class="col-12 col-md-6 col-lg-3 mb-3">
            <label for="border_crossing" class="form-label">
              Border Crossing
            </label>
            <input v-model="order.border_crossing" type="text" class="form-control" id="border_crossing"
                   placeholder="Enter border crossing">
          </div>

          <div class="col-12 col-md-6 col-lg-3 mb-3">
            <label for="conditions_of_carriage" class="form-label">
              Conditions of Carriage
              <span class="text-danger">*</span>
            </label>
            <Multiselect v-model="order.conditions_of_carriage" :options="incotermCombinations"
                         placeholder="Conditions of carriage"/>
            <div class="mt-1" v-show="!order.conditions_of_carriage">
              <span class="text-danger fw-medium">Required field</span>
            </div>
          </div>

          <div class="col-12 col-md-6 mb-3">
            <div class="row gy-3">

              <div class="col-12 col-lg-6">
                <label for="quantity" class="form-label d-block">
                  Quantity
                  <span class="text-danger ">*</span>
                  <small class="text-muted ms-2">(1 - 300)</small>
                </label>
                <div class="input-step w-100">
                  <button type="button" class="minus"
                          @click="order.quantity <= 1 ? order.quantity = 1 : order.quantity--">
                    –
                  </button>
                  <input v-model="order.quantity" id="quantity" type="number" step="any"
                         class="order-quantity w-100" min="1" max="300" @mouseleave="validateQuantity"/>
                  <button type="button" class="plus"
                          @click="order.quantity > 299 ? order.quantity = 300 : order.quantity++">+
                  </button>
                </div>
                <div class="mt-1" v-show="!order.quantity">
                  <span class="text-danger fw-medium">Required field</span>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <label for="agreed_rate" class="form-label">
                  Agreed Rate
                  <span class="text-danger">*</span>
                  <small class="text-muted ms-2">(0 - 100,000)$</small>
                </label>
                <input v-model="order.agreed_rate" step="any" type="number" class="form-control" id="agreed_rate"
                       placeholder="Enter agreed rate" @mouseleave="validateAgreedRate">
                <div class="mt-1" v-show="!order.agreed_rate && order.agreed_rate !== 0">
                  <span class="text-danger fw-medium">Required field</span>
                </div>
              </div>

              <div class="col-12 col-lg-6">
                <label for="tonne" class="form-label d-block">
                  Tonne
                  <span class="text-danger">*</span>
                  <small class="text-muted ms-2">(1 - 1000)</small>
                </label>
                <div class="input-step w-100">
                  <button type="button" class="minus"
                          @click="order.weight <= 1 ? order.weight = 1 : order.weight--">
                    –
                  </button>
                  <input v-model="order.weight" id="tonne" type="number" step="any"
                         class="order-weight w-100" min="0.01" max="1000" @mouseleave="validateAgreedRate"/>
                  <button type="button" class="plus"
                          @click="order.weight > 9999 ? order.weight = 1000 : order.weight++">+
                  </button>
                </div>
                <div class="mt-1" v-show="!order.weight">
                  <span class="text-danger fw-medium">Required field</span>
                </div>
              </div>

              <div class="col-12 col-lg-6">
                <label for="per_tonne" class="form-label">
                  Per Tonne
                  <span class="text-danger">*</span>
                  <small class="text-muted ms-2">(0 - 100,000)$</small>
                </label>
                <input v-model="order.agreed_rate_per_ton" type="number" class="form-control" id="per_tonne"
                       placeholder="Per tonne" step="any" @mouseleave="validateAgreedRate">
                <div class="mt-1" v-show="!order.agreed_rate_per_ton">
                  <span class="text-danger fw-medium">Required field</span>
                </div>
              </div>

              <div class="col-12">
                <div class="form-check">
                  <input v-model="order.paid_telegram" class="form-check-input" type="checkbox"
                         id="paid_telegram">
                  <label class="form-check-label" for="paid_telegram">
                    Paid Telegram
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 mb-3">
            <label for="containers" class="form-label">
              Wagons
            </label>
            <textarea class="form-control" v-model="order.wagons" placeholder="Enter wagons"
                      :rows="5"></textarea>
          </div>

          <div class="col-12">
            <b-button v-if="saving" type="button" variant="success" class="w-100">Saving...</b-button>
            <b-button v-else-if="!saving" type="submit" variant="success" class="w-100">Save</b-button>
          </div>

        </form>
      </b-card>
    </b-col>
  </b-row>
</template>